import React, { Component } from 'react';
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'

class EngpoolAudioRecod extends Component{
constructor(props){
    super(props);
    this.state = {
        audioDetails : {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }  

    }
    this.recFile = "N|0|N"
    this.recTime = "0"
    this.recStatus= "N"

}


handleAudioStop(data){
    console.log(data)

    this.recFile ="0|0|0"
    this.recTime ="1"
    this.recStatus ="Y"
    this.setState({ audioDetails: data });


}

handleAudioUpload(file) {
    console.log(file);
}
handleRest(){
    const reset = {
    url: null,
    blob: null,
    chunks: null,
    duration: {
    h: null,
    m: null,
    s: null,
    }
    }

    this.recFile = "N|0|N"
    this.recTime = "0"
    this.recStatus= "N"
    
    this.setState({ audioDetails: reset });
}

render(){
 return(
     <div>
    <Recorder
    record={true}
    title={"New recording"}
    audioURL={this.state.audioDetails.url}
    showUIAudio
    handleAudioStop={data => this.handleAudioStop(data)}
    handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
    handleAudioUpload={data => this.handleAudioUpload(data)}
    handleRest={() => this.handleRest()} />
    
    <form name="frm">    
	<input type="hidden" name="recFile"   value={this.recFile} />
	<input type="hidden" name="recTime"   value={this.recTime} />
	<input type="hidden" name="recStatus" value={this.recStatus} />
    </form>
    </div>
 )
}
}
export default EngpoolAudioRecod;