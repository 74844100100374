import logo from './logo.svg';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';
import EngpoolAudioRecod from './components/EngpoolAudioRecord.js';
import 'react-app-polyfill/ie11';

function App() {
  return (        
    <Router >
    <Route exact={true}  path="/" component={EngpoolAudioRecod} />
    </Router>
  );
}

export default App;
